import { render, staticRenderFns } from "./MenDianHangYeDetail.vue?vue&type=template&id=0cf86758&scoped=true&"
import script from "./MenDianHangYeDetail.vue?vue&type=script&lang=js&"
export * from "./MenDianHangYeDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf86758",
  null
  
)

export default component.exports